var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('basic-form',{attrs:{"title":_vm.title,"isLoading":_vm.loading},on:{"save":_vm.save,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}}},[_c('template',{slot:"fields"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-1/2 mr-3"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_role')
                ? _vm.errors.first('id_role')
                : '',"variant":_vm.showValidation && _vm.errors.first('id_role') ? 'danger' : '',"label":'Rol'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"id_role","placeholder":"Seleccione una opción","disabled":_vm.loading,"textAttribute":"name","valueAttribute":"id","options":_vm.roles,"variant":_vm.showValidation && _vm.errors.first('id_role') ? 'danger' : ''},model:{value:(_vm.form.id_role),callback:function ($$v) {_vm.$set(_vm.form, "id_role", $$v)},expression:"form.id_role"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('first_name')
                ? _vm.errors.first('first_name')
                : '',"variant":_vm.showValidation && _vm.errors.first('first_name') ? 'danger' : '',"label":'Nombres'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"first_name","placeholder":"Ingrese los nombres","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('first_name') ? 'danger' : ''},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('last_name')
                ? _vm.errors.first('last_name')
                : '',"variant":_vm.showValidation && _vm.errors.first('last_name') ? 'danger' : '',"label":'Apellidos'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"last_name","placeholder":"Ingrese los apellidos","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('last_name') ? 'danger' : ''},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('email')
                ? _vm.errors.first('email')
                : '',"variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : '',"label":'Correo'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|simple_email'),expression:"'required|simple_email'"}],attrs:{"data-vv-validate-on":"input","name":"email","placeholder":"Ingrese el correo electrónico","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : ''},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),(_vm.deletable)?[_c('p',{staticClass:"uppercase font-bold text-sm my-auto"},[_vm._v("Opcional")]),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidationPasswords && _vm.errors.first('old_password')
                  ? _vm.errors.first('old_password')
                  : '',"variant":_vm.showValidationPasswords && _vm.errors.first('old_password')
                  ? 'danger'
                  : '',"label":'Contraseña actual'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.changingPassword ? 'required' : ''),expression:"changingPassword ? 'required' : ''"}],attrs:{"data-vv-validate-on":"input","name":"old_password","placeholder":"Ingrese la contraseña actual","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidationPasswords && _vm.errors.first('old_password')
                    ? 'danger'
                    : ''},model:{value:(_vm.passwords_form.old_password),callback:function ($$v) {_vm.$set(_vm.passwords_form, "old_password", $$v)},expression:"passwords_form.old_password"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidationPasswords && _vm.errors.first('new_password')
                  ? _vm.errors.first('new_password')
                  : '',"variant":_vm.showValidationPasswords && _vm.errors.first('new_password')
                  ? 'danger'
                  : '',"label":'Nueva contraseña'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.changingPassword ? 'required' : ''),expression:"changingPassword ? 'required' : ''"}],attrs:{"data-vv-validate-on":"input","name":"new_password","placeholder":"Ingrese la nueva contraseña","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidationPasswords && _vm.errors.first('new_password')
                    ? 'danger'
                    : ''},model:{value:(_vm.passwords_form.new_password),callback:function ($$v) {_vm.$set(_vm.passwords_form, "new_password", $$v)},expression:"passwords_form.new_password"}})],1),_c('div',{staticClass:"flex items-end justify-end"},[_c('t-button',{attrs:{"type":"button","disabled":_vm.passwordLoading},on:{"click":_vm.changePassword}},[_vm._v("Actualizar contraseña "),(_vm.passwordLoading)?_c('t-loader',{attrs:{"extraClass":"ml-2"}}):_vm._e()],1)],1)]:[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('password')
                  ? _vm.errors.first('password')
                  : '',"variant":_vm.showValidation && _vm.errors.first('password') ? 'danger' : '',"label":'Contraseña'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"password","placeholder":"Ingrese la contraseña","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('password') ? 'danger' : ''},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)]],2),_c('div',{staticClass:"w-full relative md:w-1/2 mx-1"},[_c('div',{staticClass:"flex flex-col items-center justify-around mt-2 mb-10"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-0 md:pr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_profile_picture')
                  ? _vm.errors.first('url_profile_picture')
                  : '',"variant":_vm.showValidation && _vm.errors.first('url_profile_picture')
                  ? 'danger'
                  : ''}},[_c('label',{staticClass:"block text-left font-bold"},[_vm._v("Foto de perfil")]),_c('cropper-upload',{attrs:{"file":_vm.form.profile_picture,"externalUrl":typeof _vm.form.profile_picture === 'string'
                    ? _vm.form.profile_picture
                    : null,"title":"Foto de perfil","aspectRatio":1,"height":"250px","backgroundColor":"bg-white"},on:{"update:file":function($event){return _vm.$set(_vm.form, "profile_picture", $event)}}})],1)],1),(_vm.deletable)?_c('div',{staticClass:"absolute bottom-0 w-full flex justify-center"},[_c('t-button',{attrs:{"type":"button","variant":"error"},on:{"click":_vm.deleteElement}},[_vm._v("Eliminar usuario")])],1):_vm._e()])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }