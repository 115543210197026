<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('id_role')
                  ? errors.first('id_role')
                  : ''
              "
              :variant="
                showValidation && errors.first('id_role') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Rol'"
            >
              <t-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_role"
                name="id_role"
                placeholder="Seleccione una opción"
                :disabled="loading"
                textAttribute="name"
                valueAttribute="id"
                :options="roles"
                :variant="
                  showValidation && errors.first('id_role') ? 'danger' : ''
                "
              ></t-select>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('first_name')
                  ? errors.first('first_name')
                  : ''
              "
              :variant="
                showValidation && errors.first('first_name') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Nombres'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.first_name"
                name="first_name"
                placeholder="Ingrese los nombres"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('first_name') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('last_name')
                  ? errors.first('last_name')
                  : ''
              "
              :variant="
                showValidation && errors.first('last_name') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Apellidos'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.last_name"
                name="last_name"
                placeholder="Ingrese los apellidos"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('last_name') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('email')
                  ? errors.first('email')
                  : ''
              "
              :variant="showValidation && errors.first('email') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Correo'"
            >
              <t-input
                v-validate="'required|simple_email'"
                data-vv-validate-on="input"
                v-model="form.email"
                name="email"
                placeholder="Ingrese el correo electrónico"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('email') ? 'danger' : ''
                "
              />
            </t-input-group>

            <template v-if="deletable">
              <p class="uppercase font-bold text-sm my-auto">Opcional</p>
              <t-input-group
                :feedback="
                  showValidationPasswords && errors.first('old_password')
                    ? errors.first('old_password')
                    : ''
                "
                :variant="
                  showValidationPasswords && errors.first('old_password')
                    ? 'danger'
                    : ''
                "
                class="w-full md:ml-2 md:mr-2 my-4"
                :label="'Contraseña actual'"
              >
                <t-input
                  v-validate="changingPassword ? 'required' : ''"
                  data-vv-validate-on="input"
                  v-model="passwords_form.old_password"
                  name="old_password"
                  placeholder="Ingrese la contraseña actual"
                  autocomplete="off"
                  :disabled="loading"
                  type="text"
                  :variant="
                    showValidationPasswords && errors.first('old_password')
                      ? 'danger'
                      : ''
                  "
                />
              </t-input-group>

              <t-input-group
                :feedback="
                  showValidationPasswords && errors.first('new_password')
                    ? errors.first('new_password')
                    : ''
                "
                :variant="
                  showValidationPasswords && errors.first('new_password')
                    ? 'danger'
                    : ''
                "
                class="w-full md:ml-2 md:mr-2 my-4"
                :label="'Nueva contraseña'"
              >
                <t-input
                  v-validate="changingPassword ? 'required' : ''"
                  data-vv-validate-on="input"
                  v-model="passwords_form.new_password"
                  name="new_password"
                  placeholder="Ingrese la nueva contraseña"
                  autocomplete="off"
                  :disabled="loading"
                  type="text"
                  :variant="
                    showValidationPasswords && errors.first('new_password')
                      ? 'danger'
                      : ''
                  "
                />
              </t-input-group>
              <div class="flex items-end justify-end">
                <t-button
                  type="button"
                  @click="changePassword"
                  :disabled="passwordLoading"
                  >Actualizar contraseña
                  <t-loader v-if="passwordLoading" extraClass="ml-2"
                /></t-button>
              </div>
            </template>
            <template v-else>
              <t-input-group
                :feedback="
                  showValidation && errors.first('password')
                    ? errors.first('password')
                    : ''
                "
                :variant="
                  showValidation && errors.first('password') ? 'danger' : ''
                "
                class="w-full md:ml-2 md:mr-2 my-4"
                :label="'Contraseña'"
              >
                <t-input
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="form.password"
                  name="password"
                  placeholder="Ingrese la contraseña"
                  autocomplete="off"
                  :disabled="loading"
                  type="text"
                  :variant="
                    showValidation && errors.first('password') ? 'danger' : ''
                  "
                />
              </t-input-group>
            </template>
          </div>

          <div class="w-full relative md:w-1/2 mx-1">
            <div class="flex flex-col items-center justify-around mt-2 mb-10">
              <t-input-group
                :feedback="
                  showValidation && errors.first('url_profile_picture')
                    ? errors.first('url_profile_picture')
                    : ''
                "
                :variant="
                  showValidation && errors.first('url_profile_picture')
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:mr-0 md:pr-2"
              >
                <label class="block text-left font-bold">Foto de perfil</label>
                <cropper-upload
                  :file.sync="form.profile_picture"
                  :externalUrl="
                    typeof form.profile_picture === 'string'
                      ? form.profile_picture
                      : null
                  "
                  title="Foto de perfil"
                  :aspectRatio="1"
                  height="250px"
                  backgroundColor="bg-white"
                />
              </t-input-group>
            </div>
            <div
              class="absolute bottom-0 w-full flex justify-center"
              v-if="deletable"
            >
              <t-button type="button" variant="error" @click="deleteElement"
                >Eliminar usuario</t-button
              >
            </div>
          </div>
        </div>
      </template>
    </basic-form>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
    passwordLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {},
      passwords_form: {
        old_password: "",
        new_password: ""
      },
      showValidation: false,
      showValidationPasswords: false
    }
  },
  computed: {
    changingPassword() {
      return (
        this.deletable &&
        (this.passwords_form.old_password !== "" ||
          this.passwords_form.new_password !== "")
      )
    },
    roles() {
      return this.$store.getters["global/getItems"](
        "role",
        "unpaginated_elements"
      )
    }
  },
  methods: {
    changePassword() {
      this.$validator.validateAll()
      this.showValidationPasswords = true
      if (
        this.passwords_form.new_password &&
        this.passwords_form.new_password !== "" &&
        this.passwords_form.old_password &&
        this.passwords_form.old_password !== ""
      ) {
        this.$emit("changePassword")
      }
    },
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.$emit("save")
        }
      })
    },

    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente al usuario.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.form.id,
                  status: 2
                },
                route: "/user",
                module: "user"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/usuarios")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    }
  },
  created() {
    this.getItems("/role/all", "role", true)
  }
}
</script>
