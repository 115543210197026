<template>
  <div class="px-5">
    <Form
      title="Crear jugador"
      ref="userForm"
      :loading.sync="loading"
      @save="save"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false,
      form: {}
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    save() {
      let form = this.$refs.userForm.form
      this.loading = true
      this.$store
        .dispatch("global/create", {
          payload: {
            id_role: form.id_role,
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
            password: form.password,
            profile_picture: form.profile_picture
          },
          route: "/user/save",
          module: "user"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          this.loading = false
          if ([200, 201].includes(response.code)) {
            this.$router.push("/usuarios")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {}
}
</script>
